<template>
  <div class="animated fadeIn p-3" style="margin: auto -20px">
    <!-- event info -->
    <b-modal
      size="xl"
      :title="infoModalCalendarTitle"
      class="modal-info"
      v-model="infoModalCalendar"
      hide-footer
      @ok="infoModalCalendar = false"
    >
      <!-- div v-html="infoModalCalendarContent" style="margin-bottom:40px;"></div -->
      <event-info :eventData="eventSelectedData"></event-info>
      <template #modal-footer="{ ok }">
        <b-button
          variant="warning"
          :to="{ name: 'ClientAppointmentEdit', params: { id: infoModalEntryId } }"
          >Edit</b-button
        >
        <b-button @click="ok()">Close</b-button>
      </template>
    </b-modal>

    <!-- event scheduler -->

    <Calendar
      :events="events"
      @dates-set="onDatesSet($event)"
      @event-clicked="eventSelected($event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Calendar from '@/components/Calendar'
import EventInfo from './~EventInfo'

export default {
  components: {
    Calendar,
    EventInfo,
  },
  name: 'CalendarView',
  data() {
    return {
      startStr: null,
      endStr: null,
      eventSelectedData: {},
      // event info modal
      infoModalCalendar: false,
      infoModalCalendarTitle: '',
      infoModalCalendarContent: '',
      infoModalCalendarCancelDisabled: true,
      infoModalEntryId: null,
    }
  },
  computed: {
    ...mapGetters(['clientAppointments', 'currentFranchise']),
    events() {
      return [...this.clientAppointments].map((e) => ({
        id: e.id,
        title:
          (e.client.fname || '') +
          ' | ' +
          (e.assigned.length ? e.assigned[0].staff.profile.fname : ''),
        start: `${e.startDate}T${e.startTime}`,
        classNames: [
          'calendar-event',
          e.consultation ? 'calendar-event-consultation' : 'calendar-event-client-visit',
          'calendar-event-' + this.$options.filters.lowerHyphen(e.statusLabel),
        ],
      }))
    },
  },
  methods: {
    ...mapActions(['getClientAppointments']),
    eventSelected(eid) {
      const event = this.clientAppointments.find((e) => e.id === parseInt(eid))
      if (!event) {
        return
      }
      this.eventSelectedData = event
      // const serviceList = event.serviceGroup.name + ': ' + event.services.map(e => (`${e.category.name}: ${e.name}`)).join(', ')
      // const location = event.street1 + ' ' + event.street2 + ' ' + event.city + ' ' + event.zipCode
      // const datetime = this.$d(`${event.startDate}T${event.startTime}`)
      this.infoModalCalendarTitle =
        `${event.client.fname} ${event.client.lname}` + ' (#' + event.client.id + ')'
      this.infoModalEntryId = event.id
      // information for item click
      // this.infoModalCalendarContent = '<p></p><b>' + (serviceList ? (serviceList + '</b><br />') : '') +
      //   datetime.format('MM/DD/YY') + ' at ' + datetime.format('h:mmA') + ' (' + event.duration + ' hrs)<br />' +
      //   'Status: ' + this.$options.filters.capitalize(event.statusLabel) + '<br />' +
      //   process.env.VUE_APP_EMPLOYEE1 + ': ' + '@TODO' + '<br />' +
      //   'Subject: ' + serviceList + '<br />' +
      //   'Location: ' + '<a href="https://www.google.com/maps/place/' + location.replace(/ /g, '+') + '" target="_blank"">' + location + '</a><br />' +
      //   'Ref #: ' + event.id + '</p>' +
      //   '<p>' + event.fullDescription + '</p>'
      // if (event.confirmed === '1') {
      //   this.infoModalCalendarContent += '<span class="cal_event_confirmed"><i class="fa fa-check-circle"></i> Confirmed</span>'
      // }
      this.infoModalCalendar = true
    },
    async onDatesSet(data) {
      if (data.startStr !== this.startStr && data.endStr !== this.endStr) {
        this.startStr = data.startStr
        this.endStr = data.endStr
        const params = {
          'startDate[after]': this.$d(this.startStr).format('YYYY-MM-DD'),
          'startDate[strictly_before]': this.$d(this.endStr).format('YYYY-MM-DD'),
          // franchiseCode: this.franchiseCode,
          pagination: false,
          status: ['new', 'in-progress', 'updated', 'complete', 'canceled'],
        }
        if (this.currentFranchiseCode) {
          params.franchiseCode = this.currentFranchiseCode
        }
        if (this.isStaff) {
          params.assigned = {}
          params.assigned.staff = this.currentUser.id
        }
        await this.getClientAppointments(params)
      }
    },
  },
}
</script>
